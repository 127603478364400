<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <h5 class="m-0 font-weight-bolder">{{ "Obras" }}</h5>
            <div class="d-flex align-items-center">
              <div>
                <b-link :to="{ name: 'createProject' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ "Añadir nueva obra" }}
                  </b-button>
                </b-link>
              </div>

              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </div>
          </div>
          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="6">
                    <b-input-group class="input-group mt-2">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        class="d-inline-block"
                        :placeholder="'Búsqueda'"
                        @keydown.enter.native="handleSearch"
                      />

                      <b-button
                        @click="handleSearch"
                        variant="primary"
                        size="sm"
                        class="mr-1 ml-1"
                      >
                        {{ "Buscar" }}
                      </b-button>
                    </b-input-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fecha_ini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fecha_fin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group :label="'Estado'" label-for="filter-order">
                      <v-select
                        v-model="status"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectStatus"
                      >
                        <template slot="option" slot-scope="option">
                          {{ statusProjectsText[option.name] }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ statusProjectsText[option.name] }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group :label="'Cliente'" label-for="filter-client">
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectClients"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              <p class="m-0">{{ data.item.name }}</p>
              <span v-if="data.item.SKU" class="font-small">{{
                data.item.SKU
              }}</span>
            </template>
            <template #cell(client)="data">
              <span v-if="data.item.client">
                <b-link
                  :to="{
                    name: 'viewClient',
                    params: { id: data.item.client.id },
                  }"
                  class="link"
                >
                  {{ data.item.client.name }}
                </b-link>
              </span>
            </template>
            <template #cell(status)="data">
              <b-badge
                v-if="statusProjectsVariant[data.item.status]"
                :variant="statusProjectsVariant[data.item.status]"
              >
                {{ statusProjectsText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(tasks)="data">
              <strong>{{ data.item.total_tasks_complete }}</strong> /
              {{ data.item.total_tasks }}
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  v-if="data.item.pdf"
                  :href="data.item.pdf"
                  target="_blank"
                  download
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'viewProject', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link
                  :to="{ name: 'editProject', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  @click="deleteProject(data.item.id, data.item.name)"
                  class="d-inline-block px-50 text-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BCollapse,
  BFormGroup,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp } from "@/libs/helpers";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BCollapse,
    config,
    vSelect,
    flatPickr,
    BFormGroup,
  },
  data() {
    return {
      visibleFilter: false,
      statusProjectsText: config.statusProjectsText,
      statusProjectsVariant: config.statusProjectsVariant,
      date_ini: "",
      date_fin: "",
      client: "",
      status: "",
      log: [],
      sortBy: "",
      sortDesc: "",
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Cliente",
          key: "client",
        },
        {
          label: "Trabajos completados",
          key: "tasks",
        },
        {
          label: "Estado",
          key: "status",
        },
        {
          label: "Imputadas",
          key: "total_hours",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      items: "projects/getItems",
      totalItems: "projects/getTotalItems",
      selectClients: "clients/getSelectClients",
      selectStatus: "projects/getSelectStatus",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortDesc() {
      this.currentPage = 1;
      this.chargeData();
    },
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
    date_fin() {
      this.currentPage = 1;
      this.chargeData();
    },
    date_ini() {
      this.currentPage = 1;
      this.chargeData();
    },
    orderby() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  created() {
    this.pageLength = JSON.parse(localStorage.getItem("per_pageProj")) || 10; // value or null
    this.searchTerm = JSON.parse(localStorage.getItem("searchProj")) || ""; // value or null
    this.currentPage = JSON.parse(localStorage.getItem("currentPageProj")) || 1; // value or null
    this.date_ini = JSON.parse(localStorage.getItem("date_iniProj")) || ""; // value or null
    this.date_fin = JSON.parse(localStorage.getItem("date_finProj")) || ""; // value or null
    this.status = JSON.parse(localStorage.getItem("statusProj")) || ""; // value or null
    this.sortBy = JSON.parse(localStorage.getItem("orderByProj")) || ""; // value or null
    this.client = JSON.parse(localStorage.getItem("clientProj")) || ""; // value or null
    this.visibleFilter =
      JSON.parse(localStorage.getItem("visibleFilterProj")) || false; // value or null

    this.chargeData();
    this.getSelectStatus();
    this.getSelectClients();
  },
  methods: {
    ...mapActions({
      list: "projects/getListProjects",
      delete: "projects/delete",
      getSelectStatus: "projects/selectStatus",
      getSelectClients: "clients/selectClients",
    }),
    chargeData() {
      let orderBy = "";

      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }
      let date_ini = "";
      if (this.date_ini) {
        date_ini = FlatPickrToTimestamp(this.date_ini);
      }
      let date_fin = "";
      if (this.date_fin) {
        date_fin = FlatPickrToTimestamp(this.date_fin);
      }
      let client = this.client ? this.client.id : "";

      localStorage.setItem("per_pageProj", JSON.stringify(this.pageLength));
      localStorage.setItem("currentPageProj", JSON.stringify(this.currentPage));
      localStorage.setItem("searchProj", JSON.stringify(this.searchTerm));
      localStorage.setItem("date_iniProj", JSON.stringify(this.date_ini));
      localStorage.setItem("date_finProj", JSON.stringify(this.date_fin));
      localStorage.setItem("statusProj", JSON.stringify(this.status));
      localStorage.setItem("orderByProj", JSON.stringify(this.sortBy));
      localStorage.setItem("clientProj", JSON.stringify(this.client));
      localStorage.setItem(
        "visibleFilterProj",
        JSON.stringify(this.visibleFilter)
      );

      this.list({
        client,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        orderBy,
        status: this.status,
        date_ini,
        date_fin,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteProject(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
